/** @jsx jsx */
import { jsx } from "theme-ui"
import { RiSendPlane2Line } from "react-icons/ri"

import Layout from "../components/layout"
import SEO from "../components/seo"

const submitStyles = {
  submitLayout: {
    backgroundColor: "coverBackground",
    pb: 5,
  },
  container: {
    maxWidth: "786px",
    m: "0 auto",
    pt: [3, 4, 4, 5],
    px: [3, 4, 4, 5],
  },
  heading: {
    fontWeight: 900,
    fontSize: [4, 5],
    mb: 0,
  },
  line: {
    m: "24px 0 36px",
    opacity: 0.1,
  },
}

const Submit = () => (
  <Layout className="submit-page" sx={submitStyles.submitLayout}>
    <SEO
      title="Submit • Stackrole"
      description="Get your Jamstack template listed on Stackrole"
    />
    <div sx={submitStyles.container}>
      <h1 sx={submitStyles.heading}>Submit Your Jamstack Template</h1>
      <div className="description">
        <p>
          We are currently accepting only{" "}
          <a href="https://jamstack.org/">Jamstack</a> Templates, please make
          sure your Template is built with a{" "}
          <a href="https://www.staticgen.com/">Static Site Generator</a> with
          functional and beutiful design.
        </p>
      </div>
      <form
        className="submit-form"
        action="/thanks"
        name="submit-template"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="submit-template" />
        <p>
          <label htmlFor="name">
            Author Name
            <input type="text" name="name" id="name" required />
          </label>
        </p>
        <p>
          <label htmlFor="email">
            Author Email
            <input type="email" name="email" id="email" required />
          </label>
        </p>
        <hr sx={submitStyles.line}></hr>
        <p>
          <label htmlFor="name">
            Template Name
            <input type="text" name="name" id="name" required />
          </label>
        </p>
        <p>
          <label htmlFor="url">
            Project / Github URL
            <input type="url" name="url" id="url" required />
          </label>
        </p>
        <p>
          <label htmlFor="url">
            Demo URL
            <input type="url" name="url" id="url" required />
          </label>
        </p>
        <p>
          <label htmlFor="message">
            Description (Optional)
            <textarea name="message" id="message"></textarea>
          </label>
        </p>
        <p className="text-align-right">
          <button className="button" type="submit">
            Submit Template
            <RiSendPlane2Line className="icon -right" />
          </button>
        </p>
      </form>
    </div>
  </Layout>
)

export default Submit
